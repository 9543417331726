import React from "react";
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
  return (
    <div>
      <div className="container">
        <div className="privacy_text">
          {/* <div className="header-logo">
            <Link className="terms-and-conditions link" to="#">
              <img
                className="terms-and-conditions-logo"
                src={require("product-hotspot/images/logo-1.0.svg")}
                alt="logo"
              />
            </Link>
          </div> */}
          <h3 className="mt-3 mb-3">Terms and Conditions of Use for URush</h3>

          <h4>1. Acceptance of Terms and Conditions.-</h4>
          <p>
            By downloading, installing, or using the URush app, you agree to
            comply with the following terms and conditions of use. If you do not
            agree with these terms, please do not use the application
          </p>
          <h4>2. Proper Use.-</h4>
          <p>
            URush is a platform designed to facilitate meetings between people
            interested in dates and relationships. By using the application, you
            agree to use it respectfully, without harassing, defaming, or
            harming other users.
          </p>

          <h4>3. Registration and Profile.-</h4>
          <p>
            You must provide accurate and up-to-date information during the
            registration process. It is your responsibility to keep your profile
            updated and ensure that your photos and descriptions are truthful.
          </p>
          <p>
            a. Age Restriction: To use URush, you must be over 18 years old. By
            using URush, you confirm that you meet the required age.
          </p>
          <p>
            b. Persons Accused of Crimes Against Human Integrity: We take your
            safety very seriously. No person accused or convicted of any of the
            mentioned crimes may be part of URush. We reserve the right to
            remove those who do not meet this requirement.
          </p>
          <p>
            All accounts in our community will be subject to verification of the
            stated requirements, and we reserve the right to suspend or delete
            accounts that do not meet these requirements.
          </p>
          <p>
            In case of identifying dangerous activities that go against these
            points, we may notify the competent authorities.
          </p>
          <h4>4. User Behavior.-</h4>

          <p>
            It is not allowed to send offensive, obscene, discriminatory
            content, or content that promotes gender violence through the
            application. We do not tolerate any type of harassment,
            intimidation, or abusive behavior towards other users. We reserve
            the right to delete profiles and take legal action in case of
            non-compliance, especially in cases of gender violence.
          </p>

          <h4>5. Privacy and Security.-</h4>
          <p>
            We value your privacy. However, you understand that you will share
            information with us and with other users when using the application.
            We take measures to protect your data.
          </p>
          <p>
            a. Information Shared by the User: Some examples of information you
            provide us include: phone number, email, gender, sexual orientation,
            and date of birth. Also, when you create and customize your profile,
            you share your description, photos, and videos, where you can grant
            us access to your camera or gallery, or select only the photos you
            want to upload.
          </p>
          <p>
            We also analyze and obtain chats with other URush members and the
            content that is shared to ensure compliance with these Terms and
            Conditions.
          </p>
          <p>
            Finally, if the user consents, URush will collect geolocation
            information (latitude and longitude) from the device.
          </p>
          <p>
            b. Information Generated by Using the Application: When using URush,
            data is generated on features used, usage habits, and activity, as
            well as the devices used to access your accounts.
          </p>
          <h4>6. Intellectual Property.-</h4>
          <p>
            The content and design of URush are protected by copyright and other
            intellectual property laws. You may not copy, modify, or distribute
            any content without our permission.
          </p>

          <h4>7. Changes to the Terms.-</h4>
          <p>
            We reserve the right to modify these terms at any time. We will
            notify you of significant changes. Continued use of the application
            after the changes implies your acceptance of the new terms
          </p>

          <h4>8. Suspension.-</h4>
          <p>
            We may suspend or terminate your account if you violate the
            following terms:
          </p>

          <p>
            a. Violation of Terms and Conditions: If you significantly or
            repeatedly violate any of the terms and conditions set forth in this
            agreement, we reserve the right to suspend or terminate your account
            without prior notice. This includes, but is not limited to,
            harassment, defamation, discrimination, promotion of gender
            violence, sending offensive, obscene, or illegal content, as well as
            any other activity that harms the experience of other users.
          </p>
          <p>
            b. Inappropriate or Dangerous Behavior: If your behavior inside or
            outside the application is considered inappropriate, dangerous, or
            threatening to other users, we reserve the right to take measures to
            protect the safety and well-being of the URush community. This may
            include the suspension or termination of your account.
          </p>

          <h4>9. Disclaimer of Liability.-</h4>

          <p>
            URush and its collaborators, founders, or employees are not
            responsible for any type of damage: moral, physical, or economic,
            resulting from the relationship or interaction between users or from
            the use of the application.
          </p>
          <p>
            {" "}
            We are not responsible for physical encounters between users. We
            encourage you to practice due diligence and caution when interacting
            with other members.
          </p>
          <h4>10. Applicable Law.-</h4>
          <p>
            These terms are governed by the laws of the Ecuadorian State, and
            any dispute is subject to the exclusive jurisdiction of the courts
            of Quito, Ecuador.
          </p>
          <h4>11. Independence.-</h4>
          <p>
            URush operates autonomously, without any social, labor, or
            collaboration relationship with any educational institution.
          </p>
          <p>
            By using URush, you accept these terms and conditions in their
            entirety.
          </p>
        </div>
      </div>
    </div>
  );
}
