import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="container">
        <div className="privacy_text">
          {/* <div className="header-logo">
            <Link className="terms-and-conditions link" to="#">
              <img
                className="terms-and-conditions-logo"
                src={require("product-hotspot/images/logo-1.0.svg")}
                alt="logo"
              />
            </Link>
          </div> */}
          <h3 className="mt-3 mb-3">Privacy Policy</h3>
          <p>
            At URush, we are committed to protecting the privacy and security of
            your personal information. This Privacy Policy describes the types
            of personal information we collect, how we use it, and the measures
            we take to protect it.
          </p>
          <h3>1. Information We Collect.-</h3>
          <h4>a. Data provided:</h4>
          <p>
            When you register with URush, we may collect information to set up
            your account such as your email address and date of birth.
          </p>
          <p>
            When you are completing your profile, we collect information about
            you such as your gender, interests, preferences, approximate
            location, etc.
          </p>
          <p>
            When you use our service, you can post photos, videos, events,
            audio, text and other content, such as chats with other members.
          </p>
          <p>
            When you make a purchase, we retain the details of the transaction
            (e.g. what you bought, the date of the transaction and the price).
            The exact details depend on the payment method you choose.
          </p>
          <p>
            When you contact us, you provide us with information that may be
            necessary to help answer your inquiry. Other people may also send
            queries or reports related to you. Finally, our moderation tools and
            teams may collect additional data as part of their investigations.
          </p>
          <h4>b. Usage Information or Automatically Collected:</h4>
          <p>
            Your use of the Service generates data about your activity,
            including how you use the Service (e.g., when you logged in,
            features you have been using, actions taken, information shown to
            you, referring web pages, ads you interacted with) and how you
            interact with others (e.g., searches, matches, communication). We
            may also receive data related to the interactions you had with our
            ads on third-party websites or applications.
          </p>
          <p>
            Additionally, we automatically collect certain information about
            your use of URush, including your IP address, device information,
            browser type, and operating system.
          </p>
          <h4>c. Cookies:</h4>
          We use cookies and similar tracking technologies to improve your
          experience and collect information about how you interact with our
          platform
          <h3>2. How We Use Your Information.</h3>
          <h4>a. Service Provision:</h4>
          <p>
            We use your personal information to provide you access to URush and
            its features. Your information helps us personalize and improve our
            services to better meet your needs.
          </p>
          <h4>b. Security:</h4>
          <p>
            We also process your data to keep you and all our members safe while
            using our service. We take this responsibility very seriously and
            are always working to improve our systems and processes to help
            protect you.
          </p>
          <h4>c. Communication:</h4>
          <p>
            We may use your contact information to send you important updates,
            notifications and marketing communications related to URush.
          </p>
          <h4>d. Analysis</h4>
          <p>
            We analyze usage patterns and trends to improve the performance and
            functionality of URush. This includes monitoring user engagement,
            troubleshooting technical issues, and conducting research.
          </p>
          <h3>3. Share your Information.-</h3>
          <h4>a. Third Party Service Providers:</h4>
          <p>
            We may share your information with trusted third-party service
            providers who help us operate our platform, conduct business, and
            provide services to you.
          </p>
          <h4>b. URush Members:</h4>
          <p>
            You share data with other members when you voluntarily disclose
            information on the service for others to see.
          </p>
          <h3>c. Legal Compliance:</h3>
          <p>
            We may disclose your information to comply with applicable laws,
            regulations, legal process or enforceable government requests.
          </p>
          <h3>4. Data Security.-</h3>
          <p>
            a. We employ industry standard security measures to protect your
            personal information from unauthorized access, alteration,
            disclosure or destruction.
          </p>
          <p>
            b. Despite our best efforts, no method of transmission over the
            Internet or electronic storage is completely secure. Therefore, we
            cannot guarantee absolute security.
          </p>
          <h3>5. Your Rights.-</h3>
          <p>
            URush is not intended for use by individuals under the age of 18. We
            do not knowingly collect personal information from children.
          </p>
          <h3>7. Changes to This Privacy Policy:</h3>
          <p>
            You have the right to access, update or delete your personal
            information at any time.
          </p>
          <h3>6. Children's Privacy.-</h3>
          <p>
            URush is not intended for use by persons under 18 years of age. We
            do not knowingly collect personal information from children.
          </p>
          <h3>7. How long do we keep your data.-</h3>
          <p>
            At URush, we are committed to maintaining the privacy and security
            of your information. The personal data we collect will be stored for
            as long as necessary to fulfill the purposes for which it was
            collected, and in accordance with applicable laws. Below we detail
            the retention periods for different types of data:
          </p>
          <p>
            <b> Account Data</b>: Your profile data, such as name, email
            address, and preferences, will be retained for as long as you
            maintain an active account on our app. If you decide to delete your
            account, the data will be deleted within 3 months, unless it is
            necessary to retain certain information for legal reasons.
          </p>
          <p>
            <b>Messages and communications</b>: Messages sent and received
            through the application will be stored for 3 months after the last
            interaction, unless it is necessary to retain certain information
            for legal reasons.
          </p>
          <p>
            <b>Usage and activity data</b>: Information about your use of the
            app, such as interactions and activity patterns, will be stored for
            1 year to improve our services and for internal analysis. This data
            will be anonymized or deleted once it is no longer necessary for
            these purposes.
          </p>
          <p>
            <b>Financial Data</b>: If you make transactions within the app, the
            related data will be stored for 5 years, in compliance with tax and
            accounting laws.
          </p>
          <p>
            <b>Contact and support details</b> : Any information you provide to
            us in relation to support requests will be retained for 3 months
            after the issue has been resolved for tracking and quality of
            service purposes.
          </p>
          <h3>8. Compliance and data deletion.-</h3>
          <p>
            We carry out regular reviews to ensure that personal data is only
            kept for as long as necessary. When data is no longer needed or
            deletion is required by law, we take steps to securely delete,
            anonymize or de-identify such information.
          </p>
          <p>
            If you have questions about the data retention period or would like
            to request deletion of your data, please contact us at
            businessurush@gmail.com.
          </p>
          <h3>9. Changes to this Privacy Policy.-</h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <h3>10. Contact us.-</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at businessurush@gmail.com.
          </p>
          <p>
            By using URush, you agree to the terms described in this Privacy
            Policy
          </p>
        </div>
      </div>
    </div>
  );
}
